// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  /**
   * Add fancybox to images EventON
   */
  $('.evo_metarow_directimg').each(function () {
    let elem = $(this);
    elem.on('click', function () {
      $.fancybox.open({
        src: elem.find('img').attr('data-lazy-src'),
      });
    });
  });

  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  $('.card').matchHeight();

  $('.card__text').matchHeight();

  $('.card__title').matchHeight();

  $('.news-slider__item').matchHeight();

  $('.wpsl-select-wrap label').matchHeight();

  $('.menu-icon').on('click', function () {
    if ($('.menu-icon').hasClass('is-active')) {
      $('body').css('overflow-y', 'hidden');
    } else {
      $('body').css('overflow-y', 'auto');
    }
  });

  // var isSafari =
  //   /constructor/i.test(window.HTMLElement) ||
  //   (function (p) {
  //     return p.toString() === '[object SafariRemoteNotification]';
  //   })(
  //     !window['safari'] ||
  //       (typeof safari !== 'undefined' && window['safari'].pushNotification)
  //   );

  // var isSafari = !!navigator.userAgent.match(/Version\/[\d]+.*Safari/);
  //
  // if (isSafari) {
  //   $('body').addClass('safari-font');
  // }

  var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  if (isSafari) {
    $('body').addClass('safari-font');
  }

  $('.news-slider').slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  $('.news-slider__item-title').matchHeight();

  $('.search-click').on('click', function () {
    if (!$('.search-form-wrap').hasClass('show')) {
      $('.search-form-wrap').addClass('show');
    } else {
      $('.search-form-wrap').removeClass('show');
    }
    $(document).mouseup(function (e) {
      var div = $('.search-form-wrap');
      if (
        !div.is(e.target) &&
        div.has(e.target).length === 0 &&
        !e.target.closest('.search-click')
      ) {
        div.removeClass('show');
      }
    });
  });

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  // ACF Google Map JS code

  /**
   *  This function will render a Google Map onto the selected jQuery element
   */

  /* global google */
  function render_map($el) {
    // var
    var $markers = $el.find('.marker');
    // var styles = []; // Uncomment for map styling

    // vars
    var args = {
      zoom: 16,
      center: new google.maps.LatLng(0, 0),
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      scrollwheel: false,
      // styles : styles // Uncomment for map styling
    };

    // create map
    var map = new google.maps.Map($el[0], args);

    // add a markers reference
    map.markers = [];

    // add markers
    $markers.each(function () {
      add_marker($(this), map);
    });

    // center map
    center_map(map);
  }

  /**
   *  This function will add a marker to the selected Google Map
   */

  var infowindow;

  function add_marker($marker, map) {
    // var
    var latlng = new google.maps.LatLng(
      $marker.attr('data-lat'),
      $marker.attr('data-lng')
    );

    // create marker
    var marker = new google.maps.Marker({
      position: latlng,
      map: map,
      //icon: $marker.data('marker-icon') //uncomment if you use custom marker
    });

    // add to array
    map.markers.push(marker);

    // if marker contains HTML, add it to an infoWindow
    if ($.trim($marker.html())) {
      // create info window
      infowindow = new google.maps.InfoWindow();

      // show info window when marker is clicked
      google.maps.event.addListener(marker, 'click', function () {
        // Close previously opened infowindow, fill with new content and open it
        infowindow.close();
        infowindow.setContent($marker.html());
        infowindow.open(map, marker);
      });
    }
  }

  /**
   *  This function will center the map, showing all markers attached to this map
   */

  function center_map(map) {
    // vars
    var bounds = new google.maps.LatLngBounds();

    // loop through all markers and create bounds
    $.each(map.markers, function (i, marker) {
      var latlng = new google.maps.LatLng(
        marker.position.lat(),
        marker.position.lng()
      );
      bounds.extend(latlng);
    });

    // only 1 marker?
    if (map.markers.length == 1) {
      // set center of map
      map.setCenter(bounds.getCenter());
    } else {
      // fit to bounds
      map.fitBounds(bounds);
    }
  }

  /**
   *  This function will render each map when the document is ready (page has loaded)
   */

  $('.acf-map').each(function () {
    render_map($(this));
  });

  /**
   * Add fancybox to images
   */
  $('.gallery-item')
    .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
    .attr('rel', 'gallery')
    .attr('data-fancybox', 'gallery');
  $(
    '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  ).fancybox({
    minHeight: 0,
    helpers: {
      overlay: {
        locked: false,
      },
    },
  });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 300 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  let time = 100;
  $('.eventon_list_event.evo_eventtop .desc_trig').each(function () {
    let $this = $(this);
    setTimeout(function () {
      $this.trigger('click');
    }, time);
    time += 500;
  });

  $('.show-form').on('click', function () {
    $('.flex-events__form-content').toggleClass('hidden-form');
  });

  $('.evcal_evdata_row').on('click', function () {
    $('.evo_event_main_img').data('fancybox');
  });

  // jQuery code goes here
  // $('.wpsl-arrow-up').on('click', function () {
  //   $('#wpsl-stores').animate({
  //     scrollTop: -150,
  //   });
  // });
  // $('.wpsl-arrow-down').on('click', function () {
  //   $('#wpsl-stores').animate({
  //     scrollTop: 150,
  //   });
  // });

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }

  $('#wpsl-gmap')
    .find('[role=button]')
    .each(function () {
      $(this).on('click', function () {
        let windAttr = $('.wpsl-info-window').attr('data-store-id');
        $('.store-item[data-store-id = ' + windAttr + ']').outerHeight();
        if (
          $('.store-item[data-store-id = ' + windAttr + ']').hasClass('active')
        ) {
          console.log('test');
          return;
        } else {
          $('.store-item').removeClass('active');
          $('.store-item[data-store-id = ' + windAttr + ']').addClass('active');
          let elemDistance = $(
            '.store-item[data-store-id =' + windAttr + ']'
          ).offset().top;
          let wrapDistance = $('#wpsl-stores').offset().top;
          let dif = elemDistance - wrapDistance;
          console.log(elemDistance);
          console.log(wrapDistance);
          console.log(dif);
          $('#wpsl-stores').animate(
            {
              scrollTop: dif,
            },
            1000
          );
        }
      });
    });
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});

jQuery('a')
  .filter(function () {
    return this.hostname && this.hostname !== location.hostname;
  })
  .addClass('external');
